import React, { useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Loader } from "semantic-ui-react";
import "App.css";

import Provider from "services/api-data";

import AdminLogin from "pages/admin/AdminLogin";
import AdminDashboard from "pages/admin/AdminDashboard";
import AdminDashboardProducts from "pages/admin/AdminDashboardProducts";
import AdminDashboardSuppliers from "pages/admin/AdminDashboardSuppliers";
import AdminDashboardCategories from "pages/admin/AdminDashboardCategories";
import AdminDashboardOrders from "pages/admin/AdminDashboardOrders";
import AdminDashboardPayments from "pages/admin/AdminDashboardPayments";
import AdminDashboardInvoices from "pages/admin/AdminDashboardInvoices";
import AdminDashboardNotifications from "pages/admin/AdminDashboardNotifications";
import AdminDashboardBills from "pages/admin/AdminDashboardBills";
import AdminDashboardCountriesAndRegions from "pages/admin/AdminDashboardCountriesAndRegions";
import AdminDashboardSettings from "pages/admin/AdminDashboardSettings";
import { GoogleOAuthProvider } from "@react-oauth/google";

const Home = lazy(() => import("pages/Home"));
const Products = lazy(() => import("pages/Products"));
const Search = lazy(() => import("pages/Search"));
const ProductDetail = lazy(() => import("pages/ProductDetail"));
const Category = lazy(() => import("pages/Category"));
const Register = lazy(() => import("pages/Register"));
const TradeAssurance = lazy(() => import("pages/TradeAssurance"));
const HelpCenter = lazy(() => import("pages/HelpCenter"));
const PageNotFound = lazy(() => import("pages/pagenotfound"));
const Profile = lazy(() => import("pages/Profile"));
const Cart = lazy(() => import("pages/Cart"));
const AboutUs = lazy(() => import("pages/AboutUs"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("pages/TermsAndConditions"));
const CheckoutSuccess = lazy(() => import("pages/CheckoutSuccess"));
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};
const BASE_URLS = {
  development: "http://localhost:8000/",
  production: "https://superadmin.glossmingle.com/",
};
export const API_BASE_URL = BASE_URLS[process.env.NODE_ENV];
const SITE_URLS = {
  development: "http://localhost:3000/",
  production: "https://glossmingle.com/",
};

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const tk = localStorage.getItem("tk");
  const profile_type = localStorage.getItem("profile_type");
  useEffect(() => {
    if (!tk || profile_type !== "SUPPLIER") {
      navigate("/");
    }
  }, [profile_type, tk]);

  return children;
};

export const SITE_URL = SITE_URLS[process.env.NODE_ENV];

function App() {
  return (
    <Provider baseURL={API_BASE_URL}>
      <Router>
        <Suspense fallback={<Loader active inline style={{ margin: 10 }} />}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/search/:key" element={<Search />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route path="/brands" element={<Category />} />
            <Route path="/brands/:brand" element={<Category />} />
            <Route path="/register" element={<Register />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/helpcenter" element={<HelpCenter />} />
            <Route path="/trade" element={<TradeAssurance />} />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />

            <Route
              path="/checkout/checkout-success/:id"
              element={<CheckoutSuccess />}
            />

            <Route path="/admin" element={<AdminLogin />} />
            <Route
              path="/admin/dashboard"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/products"
              element={
                <ProtectedRoute>
                  {" "}
                  <AdminDashboardProducts />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/suppliers"
              element={
                <ProtectedRoute>
                  <AdminDashboardSuppliers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/categories"
              element={
                <ProtectedRoute>
                  {" "}
                  <AdminDashboardCategories />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/orders"
              element={
                <ProtectedRoute>
                  <AdminDashboardOrders />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/dashboard/payments"
              element={
                <ProtectedRoute>
                  <AdminDashboardPayments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/invoices"
              element={
                <ProtectedRoute>
                  <AdminDashboardInvoices />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/notifications"
              element={
                <ProtectedRoute>
                  <AdminDashboardNotifications />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/bills"
              element={
                <ProtectedRoute>
                  <AdminDashboardBills />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/countries-and-regions"
              element={
                <ProtectedRoute>
                  <AdminDashboardCountriesAndRegions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/settings"
              element={
                <ProtectedRoute>
                  <AdminDashboardSettings />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
